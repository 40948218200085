import { configureStore } from "@reduxjs/toolkit";
import { coinRankingApi } from "../services/coinRankingApi";
import { coinGeckoApi } from "../services/coinGeckoApi";
import { bingNewsApi } from "../services/bingNewsApi";

export const store = configureStore({
  reducer: {
    [coinRankingApi.reducerPath]: coinRankingApi.reducer,
    [coinGeckoApi.reducerPath]: coinGeckoApi.reducer,
    [bingNewsApi.reducerPath]: bingNewsApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(coinRankingApi.middleware)
      .concat(coinGeckoApi.middleware)
      .concat(bingNewsApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
